import React from 'react'

const Notfound = () => {
    return (
        <div>
            Notfound
        </div>
    )
}

export default Notfound